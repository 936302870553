import React from 'react'
import _ from 'lodash'
import icon_list from '../assets/google_icons_list.json'
import {
  GradientSpan,
  IconGradient,
  SpanGradient,
} from '../components/StyledSimpleComponents'
import symbols from '@iconify-json/material-symbols/icons.json'
import { subTabs, tabs } from '../consts/tabs'

//assets
import MyEcosystem from '../assets/icons/my_ecosystem.svg'
import MyEcosystemHover from '../assets/icons/my_ecosystem_hover.svg'

import CompetitiveAnalytics from '../assets/icons/competitive_analytics.svg'
import CompetitiveAnalyticsHover from '../assets/icons/competitive_analytics_hover.svg'

import SocialAnalytics from '../assets/icons/social_analytics.svg'
import SocialAnalyticsHover from '../assets/icons/social_analytics_hover.svg'

import InGameAnalytics from '../assets/icons/in_game_analytics.svg'
import InGameAnalyticsHover from '../assets/icons/in_game_analytics_hover.svg'

import HoldersInsights from '../assets/icons/holder_insights.svg'
import FinancialHealth from '../assets/icons/financial_health.svg'
import CustomExtracts from '../assets/icons/custom_extracts.svg'
import InGameAnalyticsSub from '../assets/icons/in_game_analytic.svg'
import SocialAnalyticsSub from '../assets/icons/social_analytic.svg'
import ComparativeAnalytics from '../assets/icons/comparative_analytics.svg'
import OverlapAnalysis from '../assets/icons/overlap_analysis.svg'

import { ReactComponent as IDIcon } from '../assets/support_header_id_icon.svg'
import { ReactComponent as SLAIcon } from '../assets/support_header_sla_icon.svg'
import { ReactComponent as TypeIcon } from '../assets/support_header_type_icon.svg'
import { ReactComponent as ReporterIcon } from '../assets/support_header_reporter_icon.svg'
import { ReactComponent as PreviewIcon } from '../assets/support_header_preview_icon.svg'
import { ReactComponent as BanGameIcon } from '../assets/support_header_ban_game_icon.svg'
import { ReactComponent as ReasonIcon } from '../assets/support_header_reason_icon.svg'
import { ReactComponent as TimeIcon } from '../assets/support_header_time_icon.svg'
import { ReactComponent as AssignedIcon } from '../assets/support_header_assigned_icon.svg'
import { ReactComponent as TextIcon } from '../assets/support_header_text_icon.svg'

export const HELIKA_LOGO_URL =
  '/static/media/helikaIconButton.550c82a10c0d1c4e8886ee60c3721063.svg'

const symbols_list: any = symbols

export const capitaliseFirstLetter = (word: string) => {
  const firstLetter = word.charAt(0)

  const firstLetterCap = firstLetter.toUpperCase()

  const remainingLetters = word.slice(1)

  return firstLetterCap + remainingLetters
}

export function replaceQuotes(word: string) {
  return word?.replaceAll('"', '')?.replaceAll(`'`, '')
}

export function replaceSpacesAndDashes(word: string) {
  return word?.replaceAll(' ', '_')?.replaceAll('-', '_')
}
export function lowerCaseAndReplaceSpacesAndDashes(word: string) {
  return word
    ?.replaceAll(' ', '_')
    ?.replaceAll('-', '_')
    ?.replaceAll(`'`, '')
    .toLowerCase()
}

export function pascaleCaseWithSpaces(word: string) {
  let newWord = word?.replaceAll('_', ' ')
  return _.startCase(_.toLower(newWord))
}

export function stringToIcon(text: string, defaultIcon?: any, size?: string) {
  let iconTag = 'dns'
  if (text && text !== '' && text !== null && text !== undefined) {
    iconTag = text
  } else if (
    defaultIcon &&
    defaultIcon !== '' &&
    defaultIcon !== null &&
    defaultIcon !== undefined
  ) {
    iconTag = defaultIcon
  }

  //use google symbols if necessary
  if (
    !icon_list.icons.map((icon) => icon.name).includes(text) &&
    symbols_list?.icons[text?.replace('_', '-')]
  )
    return (
      <GradientSpan>
        <SpanGradient
          className={`material-symbols-outlined md-${size ? size : '18'}`}
        >
          {text}
        </SpanGradient>
      </GradientSpan>
    )

  if (text && !icon_list.icons.map((icon) => icon.name).includes(text))
    iconTag = 'dns'
  return (
    <GradientSpan>
      <IconGradient className={`material-icons md-${size ? size : '18'}`}>
        {iconTag}
      </IconGradient>
    </GradientSpan>
  )
}

export function renderIconTabs(name: string, icon: string) {
  let iconTabs = <div style={{ flex: 1 }} />

  switch (name) {
    case tabs.MY_ECOSYSTEM:
      iconTabs = (
        <div id="MyEcosystem">
          <img id="MyEcosystemNonHover" src={MyEcosystem} alt="MyEcosystem" />
          <img
            id="MyEcosystemHover"
            src={MyEcosystemHover}
            alt="MyEcosystemHover"
          />
        </div>
      )
      break
    case tabs.COMPETITIVE_ANALYTICS:
      iconTabs = (
        <div id="CompetitiveAnalytics">
          <img
            id="CompetitiveAnalyticsNonHover"
            src={CompetitiveAnalytics}
            alt="CompetitiveAnalytics"
          />
          <img
            id="CompetitiveAnalyticsHover"
            src={CompetitiveAnalyticsHover}
            alt="CompetitiveAnalyticsHover"
          />
        </div>
      )
      break
    case tabs.SOCIAL_ANALYTICS:
      iconTabs = (
        <div id="SocialAnalytics">
          <img
            id="SocialAnalyticsNonHover"
            src={SocialAnalytics}
            alt="SocialAnalytics"
          />
          <img
            id="SocialAnalyticsHover"
            src={SocialAnalyticsHover}
            alt="SocialAnalyticsHover"
          />
        </div>
      )
      break
    case tabs.IN_GAME_ANALYTICS:
      iconTabs = (
        <div id="InGameAnalytics">
          <img
            id="InGameAnalyticsNonHover"
            src={InGameAnalytics}
            alt="InGameAnalytics"
          />
          <img
            id="InGameAnalyticsHover"
            src={InGameAnalyticsHover}
            alt="InGameAnalyticsHover"
          />
        </div>
      )
      break
    default:
      iconTabs = stringToIcon(icon)
  }
  return <>{iconTabs}</>
}

export function renderIconSubTabs(name: string, icon: string) {
  let iconSubTab = <div style={{ flex: 1 }} />

  switch (name) {
    case subTabs.HOLDER_INSIGHTS:
      iconSubTab = <img src={HoldersInsights} alt="HoldersInsights"></img>
      break
    case subTabs.FINANCIAL_HEALTH:
      iconSubTab = <img src={FinancialHealth} alt="FinancialHealth"></img>
      break
    case subTabs.CUSTOM_EXTRACTS:
      iconSubTab = <img src={CustomExtracts} alt="CustomExtracts"></img>
      break
    case subTabs.IN_GAME_ANALYTICS:
      iconSubTab = <img src={InGameAnalyticsSub} alt="InGameAnalyticsSub"></img>
      break
    case subTabs.SOCIAL_ANALYTICS:
      iconSubTab = <img src={SocialAnalyticsSub} alt="SocialAnalyticsSub"></img>
      break
    case subTabs.COMPARATIVE_ANALYTICS:
      iconSubTab = (
        <img src={ComparativeAnalytics} alt="ComparativeAnalytics"></img>
      )
      break
    case subTabs.OVERLAP_ANALYSIS:
      iconSubTab = <img src={OverlapAnalysis} alt="OverlapAnalysis"></img>
      break
    default:
      iconSubTab = stringToIcon(icon)
  }
  return (
    <div style={{ width: 'fit-content', textAlign: 'start' }}>{iconSubTab}</div>
  )
}

export function lengthTruncate(text: string, length: number) {
  if (text?.length > length) {
    return text.substring(0, length) + '...'
  } else {
    return text
  }
}

export function getIcon(icon: string, theme: string) {
  switch (icon?.toLowerCase()) {
    case 'preview': {
      return <PreviewIcon />
    }
    case 'id': {
      return <IDIcon />
    }
    case 'sla': {
      return <SLAIcon />
    }
    case 'type': {
      return <TypeIcon />
    }
    case 'reporter': {
      return <ReporterIcon />
    }
    case 'game id':
    case 'game_id': {
      return <BanGameIcon />
    }
    case 'event':
    case 'event type':
    case 'sub type':
    case 'event_type':
    case 'sub_type':
    case 'status':
    case 'description':
    case 'info':
    case 'discord ticket':
    case 'data.discord_ticket_number':
    case 'text': {
      return <TextIcon />
    }
    case 'title':
    case 'reason': {
      return <ReasonIcon />
    }
    case 'created at':
    case 'created_at': {
      return <TimeIcon />
    }
    case 'reported by':
    case 'reported_by':
    case 'wallet':
    case 'contact info':
    case 'data.contact_info':
    case 'contact_info':
    case 'assigned':
    case 'assigned to':
    case 'assigned_to': {
      return <AssignedIcon />
    }
    default: {
      return undefined
    }
  }
}

export function getDeepCopy(item: any) {
  if (_.isEmpty(item)) return
  return JSON.parse(JSON.stringify(item))
}

export function isStringEmpty(input: string, errMsg: string) {
  if (input?.trim()?.length === 0) {
    throw errMsg
  }
}

export function hasDuplicates(array: any) {
  if (_.isEmpty(array)) {
    return false
  }
  return _.some(array, function (elt, index) {
    return array.indexOf(elt) !== index
  })
}
export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step,
  )

export enum visualTypes {
  waterfall = 'waterfall',
  line = 'line',
  bar = 'bar',
  pie = 'pie',

  funnel = 'funnel',
  scatter = 'scatter',
}

export function validateEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export function sortArrayBy(array: any[], propertyToSortBy: string) {
  return array.sort((a: any, b: any) =>
    a[propertyToSortBy]?.toLowerCase() > b[propertyToSortBy]?.toLowerCase() ? 1 : -1,
  )
}

export const customFonts: any = [
  {
    label: "Comic Sans",
    value: "'Comic Sans MS', cursive, sans-serif"
  },
  {
    label: "Lobster",
    value: "'Lobster', Georgia, Times, serif",
    url: "https://fonts.googleapis.com/css?family=Lobster"
  },
  {
    label: "Paralucent",
    value: "'Paralucent",
  }
]