export function handleError(e: any, setError?: any, setMessage?: any) {
  let error = '';
  if (e && 'details' in e) {
    //case when api code defines the error itself
    error = e.details;
  } else if (e && 'data' in e && 'message' in e.data) {
    //case when there is unexpected error (db/sequalize/etc errors)
    error = e.data.message;
  } else {
    error = e.toString();
  }
  if (setMessage) {
    setMessage(error)
  }
  if (setError) {
    setError(error)
  }
  throw new Error(error)
}