
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  useTheme,
  TextField,
  Select,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingComponent'
import { Auth0Context } from '../../contexts/Auth0Context'
import { createNewEmailCampaign } from '../../utils/api/queries'
import { newColor } from '../../consts/colors'
import { useSelector } from 'react-redux'
import { isStringEmpty } from 'src/utils/string'
import _ from 'lodash'

export interface SimpleDialogProps {
  onCreated?: any
  open: boolean
  setOpen: any,
  domains: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

export default function CreateNewEmailCampaignModal(props: SimpleDialogProps) {
  const { open, setOpen, onCreated, domains } = props
  const theme = useTheme()
  const userData = useSelector((state: any) => state.user)
  const [loading, setLoading] = useState<boolean>(false)
  const { postWithAccessToken } = useContext(Auth0Context)
  const [name, setName] = useState<string>('')
  const [domain, setDomain] = useState<string>(domains[0]?.domain ? domains[0]?.domain : undefined)
  const [description, setDescription] = useState<string>('')

  const handleClose = () => {
    setOpen(false)
  }

  const resetFields = () => {
    setName('')
    setDescription('')
  }

  useEffect(() => {
    setDomain(domains[0]?.domain ? domains[0]?.domain : undefined)
  }, [domains])

  async function createNewEmailCampaignHandler() {
    try {
      setLoading(true)

      try {
        isStringEmpty(name, 'Name required')
        isStringEmpty(description, 'Description required')
        isStringEmpty(domain, 'Domain required')
      } catch (e: any) {
        toast.error(e)
        return;
      }

      if (name && description) {
        setLoading(true)
        let args: any = {
          title: name.trim(),
          description: description.trim(),
          domain_id: domains.find((item: any) => item.domain === domain)?.id,
          organization_id: userData?.organization_id
        }
        const results = await createNewEmailCampaign(
          postWithAccessToken,
          args
        )
        if (!results.results) {
          throw new Error('Could not create Email Marketing Campaign')
        }
        resetFields()
        onCreated(results.results)
        setLoading(false)
        setOpen(false)
      } else {
        toast.warning('Please enter required fields.')
        setLoading(false)
      }
    } catch (e: any) {
      console.error(e)
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? newColor.charcoal : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          minWidth: '25em',
        },
      }}
    >
      <Box
        display={'flex'} flexDirection={'column'} padding={'1em'}
        sx={{
          padding: '0em !important'
        }}
      >
        <Box
          sx={{
            borderBottom: 'solid',
            borderColor: newColor.gunmetalGray,
            padding: '1em'
          }}
        >
          <Box
            sx={{
              fontSize: '1.5em',
              fontWeight: 500
            }}
          >
            Create a Campaign
          </Box>
          <Box
            style={{
              fontSize: '0.8em',
              color: newColor.gray
            }}
          >
            Create a new Email Marketing Campaign
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '2em 5em 2em 5em'
          }}
        >
          <Box>
            Campaign Title
          </Box>
          <TextField
            // ref={emailRef}
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value)
            }}
            sx={{
              borderRadius: '0.3em',
              margin: '0.5em 0 1em 0',
              background: theme.palette.mode === 'dark' ? 'black' : 'white',
            }}
            size={'small'}
            placeholder="Add Campaign Title"
          />
          <Box>
            Description
          </Box>
          <TextField
            value={description}
            onChange={(e) => {
              setDescription(e.currentTarget.value)
            }}
            sx={{
              borderRadius: '0.3em',
              marginTop: '0.5em',
              background: theme.palette.mode === 'dark' ? 'black' : 'white',
              margin: '0.5em 0 1em 0',
            }}
            size={'small'}
            placeholder="e.g. Email sending for opening day rewards"
            multiline
            maxRows={10}
            minRows={6}
          />
          <Box>
            Domain
          </Box>
          <Select
            displayEmpty
            value={domain}
            onChange={(e) => {
              setDomain(e.target.value);
            }}
            sx={{
              width: '100%',
              height: '1.7em',
              borderRadius: '0.5em',
              textAlign: 'center',
              margin: '0.5em 0 1em 0',
            }}
          >
            {
              domains?.map((domainItem: any) => (
                <option key={`${domainItem.id}`} value={domainItem.domain}>
                  {domainItem.domain}
                </option>
              ))
            }
          </Select>
        </Box>
      </Box>
      {
        loading ? (
          <div className="w-full">
            <LoadingComponent />
          </div>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            sx={{
              borderTop: 'solid',
              borderColor: newColor.gunmetalGray,
              padding: '1em',
              width: '100%',
              justifyContent: 'end',
              minWidth: '20em'
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              className="helikaButtonClass"
              sx={{
                borderRadius: '0.5em !important',
                background: `none !important`,
                padding: '0.5em 2em 0.5em 2em'
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading || _.isEmpty(domains)}
              onClick={createNewEmailCampaignHandler}
              className="helikaButtonClass"
              sx={{
                borderRadius: '0.5em !important',
                background: 'red !important',
                padding: '0.5em 2em 0.5em 2em',
                marginRight: '1em'
              }}
            >
              Create
            </Button>
          </Box>
        )
      }
    </Dialog >
  )
}
