import _ from 'lodash';
import { handleError } from './errors';
import { addAppJsonHeader } from './shared';
import { queryTimeout } from '../number';

export class APIError extends Error {
  data: any;
  constructor(message: string, data: any) {
    super(message);
    this.name = 'APIError';
    this.data = data;
  }
}

const request = (url: string, method: RequestInit['method'], options?: RequestInit, errorHandler?: any, signal?: any) => {

  return fetch(url, {
    method,
    ...options,
    signal: !_.isNil(signal) ? signal : AbortSignal.timeout(queryTimeout)
  })
    .catch((err: any) => {
      if (
        err.name === "TimeoutError" ||
        err.name === "AbortError"
      ) {
        console.error("Timeout: It took more than 20 seconds to get the result.");
        if (errorHandler !== undefined) {
          errorHandler({ detail: "Timeout: It took more than 20 seconds to get the result." });
        }
      } else {
        throw new Error(err)
      }
    })
    .then((response) => {
      if (!response) return response;
      return response.json().then(async function (data) {
        if (response.status !== 200) {
          if (errorHandler !== undefined) {
            await errorHandler(data)
            return;
          }
          handleError(data)
        }
        return data;
      });
    })
    ;
};

export const get = (url: string, options?: RequestInit) => request(url, 'GET', options);

export const deleteReq = (url: string, options?: RequestInit) => request(url, 'DELETE', options);

export const post = (url: string, options?: RequestInit, errorHandler?: any, signal?: any) => request(url, 'POST', addAppJsonHeader(options), errorHandler, signal);

export const patch = (url: string, options?: RequestInit) => request(url, 'PATCH', addAppJsonHeader(options));

export const build_url_with_params = (url: string, params: any) => {

  if (_.isEmpty(params)) {
    return url;
  }

  let newUrl = url;
  let newParams = Object.entries(params);

  if (newParams && !_.isEmpty(newParams)) {
    newUrl += '?';
    newParams.forEach((param, index) => {
      if (!param[0] || !param[1]) return;
      if (index !== 0) newUrl += `&`;
      if (param[0] === 'id') {
        newUrl += `${param[0]}=${Number(param[1])}`
      } else {
        newUrl += `${param[0]}=${param[1]}`
      }
    })
  }

  return encodeURI(newUrl);
}
