import React, { useContext } from 'react'
import { Button } from '@mui/material'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash-es'
import { Auth0Context } from '../contexts/Auth0Context'
import { actionCreators } from '../state'
import { newColor } from '../consts/colors'

//assets
import LogOutIcon from '../assets/log_out_icon.svg'

export default function LogoutButton() {
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const dispatch = useDispatch()
  const {
    setUserData,
    setNotifications,
    setAnalyticsState,
    setGameManagementState,
    setAccountManagementState,
    setSupportState,
  } = bindActionCreators(actionCreators, dispatch)

  const { logoutAuth0 } = useContext(Auth0Context)

  async function clearAllData() {
    setUserData({
      type: 'CLEAR_DATA',
    })
    setNotifications({
      type: 'CLEAR_DATA',
    })
    setAnalyticsState({
      type: 'CLEAR_DATA',
    })
    setGameManagementState({
      type: 'CLEAR_DATA',
    })
    setAccountManagementState({
      type: 'CLEAR_DATA',
    })
    setSupportState({
      type: 'CLEAR_DATA',
    })
  }

  async function cleanLogout() {
    if (!_.isEmpty(sdk)) {
      await sdk.createEvent([
        {
          game_id: 'helika_portal',
          event_type: 'interaction_event',
          event: {
            event_sub_type: 'user_log_out',
            user_identifier: userData?.id,
            user_email: userData?.email,
            user_org_identifier: userData?.organization_id,
            user_org_name: userData?.organization_name,
          },
        },
      ])
    }
    await logoutAuth0(clearAllData)
  }

  return (
    <Button
      onClick={cleanLogout}
      sx={{
        background: 'transparent',
        border: 'none',
        outline: 'none',
        color: newColor.jazzberryJam,
        fontSize: '17px',
        fontWeight: '400',
        fontFamily: 'Paralucent Medium',
        ':hover': {
          background: 'transparent',
        },
      }}
    >
      <img
        style={{ marginRight: '0.25rem' }}
        src={LogOutIcon}
        alt="logout-icon"
      />
      LOG OUT
    </Button>
  )
}
