import React, { useContext, useEffect, useRef, useState } from 'react';
import LoadingComponent from 'src/components/LoadingComponent';
import { Box, useTheme } from '@mui/system';
import { Button, Checkbox, FormControlLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { useSelector } from 'react-redux';
import { getEmailTemplates, getAudiencesList, getActionsList, getEmailMarketingCampaign, updateAction, getEmailMarketingAction, createEmailTemplate, createEmailMarketingAction } from '../../../utils/api/queries';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { toast } from 'react-toastify';
import { customFonts, isStringEmpty } from '../../../utils/string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTeamName } from 'src/utils/user';
import { newColor } from 'src/consts/colors';
import ChooseExistingTemplateModal from 'src/components/popups/ChooseExistingTemplateModal';
import _ from 'lodash';
import DuplicateActionButtonList from './DuplicateActionButtonList';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { ReactComponent as Editable } from 'src/assets/editable_icon.svg'
import { ReactComponent as CreateNewEmailIcon } from 'src/assets/new_email_icon.svg'
import { ReactComponent as ExistingTemplateIcon } from 'src/assets/existing_template_icon.svg'
import moment from 'moment';
import { DateInputField } from 'src/components/InputFields/DateInputField';
import { sampleDesign, sampleTemplate } from 'src/utils/email';

var CryptoJS = require('crypto-js');

export default function EmailTemplateBuilder() {

    const navigate = useNavigate()
    const theme = useTheme()
    const userData = useSelector((state: any) => state.user)
    const [searchParams,] = useSearchParams();
    const { getWithAccessToken, postWithAccessToken } = useContext(Auth0Context)
    const [isLoading, setLoading] = useState<boolean>(true)
    const emailEditorRef = useRef<EditorRef>(null);
    const [templates, setTemplates] = useState<any[]>([])
    const [emailMarketingCampaign, setEmailMarketingCampaign] = useState<any>()
    const [isChooseExistingTemplateModal, setIsChooseExistingTemplateModal] = useState<boolean>(false)
    const [actionName, setActionName] = useState<string>('')
    const [editMode, setEditMode] = useState<boolean>(true)
    const [sender, setSender] = useState<string>('')
    const [subject, setSubject] = useState<string>('')
    const [audiences, setAudiences] = useState<any[]>([])
    const [audience, setAudience] = useState<any>('')
    const [actions, setActions] = useState<any[]>([])
    const [currentAction, setCurrentAction] = useState<any>()

    //frequency
    const [frequency, setFrequency] = useState<string>('once')
    const [monthlyDate, setMonthlyDate] = useState<any>(new Date())
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [isEndDateOpen, setEndDateOpen] = useState<boolean>(false)
    const [onceDate, setOnceDate] = useState<Date>(new Date())
    const [isOnceDateOpen, setOnceDateOpen] = useState<boolean>(false)
    const [isMonthlyStartDateOpen, setMonthlyStartDateOpen] = useState<boolean>(false)
    const [dailyFrequency, setDailyFrequency] = useState<number>(1)
    const [weeklyDays, setWeeklyDays] = useState<any[]>([true, true, true, true, true, true, true])
    const [monthlyDates, setMonthlyDates] = useState<string>('')

    //get campaign
    useEffect(() => {
        const getCampaignList = async () => {
            try {
                setLoading(true)
                const data = await getEmailMarketingCampaign(getWithAccessToken, searchParams.get('campaign-id'))
                if (data?.results) {
                    setEmailMarketingCampaign(data?.results)
                    let actionId = searchParams.get('action-id')
                    if (actionId) {
                        const actionData = await getEmailMarketingAction(getWithAccessToken, actionId)
                        if (actionData?.results) {
                            setCurrentAction(actionData?.results)
                        }
                    }
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }
        let newCampaignId = searchParams.get('campaign-id');
        if (_.isEmpty(newCampaignId)) {
            navigate('/email/campaigns')
            return
        }
        getCampaignList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function createSignature() {
        const crypted = CryptoJS.HmacSHA256("1", process.env.REACT_APP_UNLAYER_SECRET!)
        return crypted.toString(CryptoJS.enc.Hex)
    }

    //update current action
    useEffect(() => {
        setSender(currentAction?.sender ? currentAction?.sender : '')
        setSubject(currentAction?.subject ? currentAction?.subject : '')
        setAudience(currentAction?.audience_id ? currentAction?.audience_id : '')
        if (!_.isUndefined(currentAction?.name)) {
            setActionName(currentAction?.name ? currentAction?.name : '')
        }
        //frequency
        if (currentAction?.frequency) {
            setFrequency(currentAction.frequency?.frequency)
            switch (currentAction.frequency?.frequency) {
                case 'once':
                    setOnceDate(currentAction.frequency?.start)
                    break
                case 'daily':
                    setMonthlyDate(moment(currentAction.frequency?.start))
                    setDailyFrequency(currentAction.frequency?.interval)
                    setEndDate(currentAction.frequency?.end)
                    break
                case 'weekly':
                    let newWeeklyDays = [
                        currentAction.frequency?.days?.includes('monday'),
                        currentAction.frequency?.days?.includes('tuesday'),
                        currentAction.frequency?.days?.includes('wednesday'),
                        currentAction.frequency?.days?.includes('thursday'),
                        currentAction.frequency?.days?.includes('friday'),
                        currentAction.frequency?.days?.includes('saturday'),
                        currentAction.frequency?.days?.includes('sunday')
                    ]
                    setWeeklyDays(newWeeklyDays)
                    setMonthlyDate(moment(currentAction.frequency?.start))
                    setEndDate(currentAction.frequency?.end)
                    break;
                case 'monthly':
                    setMonthlyDates(currentAction.frequency?.dates?.toString())
                    setMonthlyDate(moment(currentAction.frequency?.start))
                    setEndDate(currentAction.frequency?.end)
            }
        } else {
            setFrequency('once')
            setOnceDate(new Date())
        }
        const unlayer = emailEditorRef.current?.editor;
        if (currentAction?.design) {
            unlayer?.loadDesign(currentAction?.design)
        } else {
            unlayer?.loadBlank()
        }
    }, [audiences, currentAction])

    const onReady: EmailEditorProps['onReady'] = (unlayer) => {
        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadTemplate(callback) or unlayer.exportHtml(callback)
        if (currentAction?.design) {
            unlayer.loadDesign(currentAction?.design);
        } else if (currentAction) {
            unlayer.loadDesign(currentAction?.design);
        } else {
            unlayer?.loadBlank()
        }

    };

    //get email template list
    useEffect(() => {
        const getTemplates = async () => {
            try {
                setLoading(true)
                const data = await getEmailTemplates(getWithAccessToken)
                if (data?.results) {
                    //note: Interweave cannot handle full htmldoc so need to remove doctype
                    let newWithFormattedHtml = data?.results?.map((template: any) => {
                        let newTemplate = { ...template }
                        newTemplate.html = template?.html?.replace(`<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">`, '')
                        return newTemplate
                    })
                    setTemplates([
                        { id: -1, name: 'Apple Email', html: sampleTemplate, design: sampleDesign },
                    ].concat(newWithFormattedHtml))
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        getTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //get actions
    useEffect(() => {
        const getActions = async () => {
            try {
                setLoading(true)
                const data = await getActionsList(getWithAccessToken, emailMarketingCampaign?.id, '')
                if (data?.results) {
                    setActions(data?.results)
                    let actionId = searchParams.get('action-id')
                    if (actionId) {
                        let foundAction = data?.results.find((actionItem: any) => {
                            return actionItem.id?.toString() === actionId?.toString()
                        })
                        setCurrentAction(foundAction)
                    }
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }
        if (!emailMarketingCampaign?.id) return
        getActions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailMarketingCampaign])

    //get audiences
    useEffect(() => {
        const getAudienceList = async () => {
            try {
                setLoading(true)
                const data = await getAudiencesList(getWithAccessToken)
                if (data?.results) {
                    setAudiences(data?.results)
                }
            } catch (e: any) {
                toast.error(e?.message)
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        getAudienceList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function saveTemplateHandler() {
        try {
            if (!emailMarketingCampaign?.id) {
                throw new Error('Error finding Email Marketing Campaign')
            }
            setLoading(true);
            try {
                isStringEmpty(actionName, 'Action Name required')
            } catch (e: any) {
                toast.error(e)
                return;
            }

            let args = {
                html: currentAction?.html,
                design: currentAction?.design,
                name: actionName,
                parent_campaign_id: emailMarketingCampaign?.id,
                organization_id: userData.organization_id
            }
            const data = await createEmailTemplate(postWithAccessToken, args)
            if (data?.results) {
                toast.success(`Successfully created Email Template`)
                setTemplates((prevState: any) => {
                    if (!prevState) return [data.results]
                    return prevState.concat(data.results)
                })
            }
        } catch (e: any) {
            toast.error(e?.message)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    async function saveHandler() {
        try {
            if (!emailMarketingCampaign?.id) {
                throw new Error('Error finding Email Marketing Campaign')
            }
            setLoading(true);
            try {
                isStringEmpty(actionName, 'Action Name required')
                isStringEmpty(sender, 'Sender required')
                isStringEmpty(subject, 'Subject required')
                isStringEmpty(audience?.toString(), 'Audience Required')
            } catch (e: any) {
                toast.error(e)
                return;
            }

            let args = {
                ...currentAction,
                name: actionName,
                sender: sender,
                subject: subject,
                audience_id: audience,
                parent_campaign_id: emailMarketingCampaign?.id,
            }
            if (!('active' in currentAction)) {
                args.active = false
            }
            switch (frequency) {
                case 'once':
                    args.frequency = {
                        frequency: frequency,
                        start: onceDate,
                    }
                    break;
                case 'daily':
                    args.frequency = {
                        frequency: frequency,
                        start: monthlyDate,
                        interval: dailyFrequency,
                    }
                    break;
                case 'weekly':
                    let days: any = []
                    if (weeklyDays[0]) days = days.concat('monday')
                    if (weeklyDays[1]) days = days.concat('tuesday')
                    if (weeklyDays[2]) days = days.concat('wednesday')
                    if (weeklyDays[3]) days = days.concat('thursday')
                    if (weeklyDays[4]) days = days.concat('friday')
                    if (weeklyDays[5]) days = days.concat('saturday')
                    if (weeklyDays[6]) days = days.concat('sunday')
                    args.frequency = {
                        frequency: frequency,
                        days: days,
                        start: monthlyDate,
                    }
                    break;
                case 'monthly':
                    let dates: any = monthlyDates?.split(',')
                    dates = dates.map((monthDate: any) => Number(monthDate?.trim()))
                    dates = dates?.sort((a: any, b: any) => a > b ? 1 : -1)
                    args.frequency = {
                        frequency: frequency,
                        start: monthlyDate,
                        dates: dates,
                    }
                    break;
            }
            if (frequency !== 'once') args.frequency.end = endDate
            let data: any = null
            if (args.id) {
                data = await updateAction(postWithAccessToken, args)
            } else {
                data = await createEmailMarketingAction(postWithAccessToken, args)
            }
            if (data?.results) {
                toast.success(`Successfully ${args.id ? 'updated action' : 'created new action'}`)
                setActions((prevState: any) => {
                    if (!prevState) return [data.results]
                    if (prevState.find((actionItem: any) => actionItem.id === data.results.id)) {
                        return prevState.map((actionItem: any) => {
                            if (actionItem.id === data.results.id) {
                                return data.results
                            }
                            return actionItem
                        })
                    } else {
                        return prevState.concat(data.results)
                    }
                })
                setCurrentAction(data.results)
            }
        } catch (e: any) {
            toast.error(e?.message)
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const header =
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                paddingRight: '0.5em'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0.5em'
                }}
            >
                <Box
                    style={{
                        fontSize: '0.8em',
                        color: newColor.gray
                    }}
                >
                    {getTeamName(userData)}
                </Box>
                <Box
                    style={{
                        fontSize: '1.2em',
                    }}
                >
                    Campaign: {emailMarketingCampaign?.title}
                </Box>
            </Box>
            <Button
                className='helikaButtonClass'
                sx={{
                    height: '3em',
                    width: '15em',
                    borderRadius: '0.5em !important',
                    background: 'red !important',
                    padding: '0.5em 2em 0.5em 2em',
                    margin: 'auto 0 0 0'
                }}
                onClick={() => {
                    navigate(`/email/campaigns?campaign-id=${emailMarketingCampaign?.id}`)
                }}
            >
                BACK TO CAMPAIGN
            </Button>
        </Box>

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '1em'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                }}
            >
                {
                    header
                }
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0.5em',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%'
                        }}
                    >
                        <StyledSvg
                            sx={{
                                '& svg, & path': {
                                    fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                                },
                                margin: 'auto 0 auto 0',
                            }}
                        >
                            <Editable />
                        </StyledSvg>
                        <input
                            style={{
                                background: theme.palette.mode === 'dark' ? newColor.graphite : newColor.white,
                                margin: '-0.1em 0.5em 0 0.5em',
                                boxShadow: 'none',
                                textOverflow: 'ellipsis',
                                marginTop: '-0.1em',
                                lineHeight: 1,
                                fontSize: '0.9em',
                                marginLeft: '0.5em',
                                borderRadius: '0.1em',
                                borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
                                color: theme.palette.text.primary,
                                padding: '0.5em 1em 0.5em 1em',
                                border: 'none',
                            }}
                            className={
                                theme.palette.mode === 'dark'
                                    ? 'textFieldClass'
                                    : 'textFieldClassLight'
                            }
                            type='text'
                            placeholder={'Enter new Action name here...'}
                            value={actionName}
                            onChange={(e) => {
                                setActionName(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.currentTarget.blur()
                                }
                            }}
                        />
                    </Box>
                    {

                        (currentAction || currentAction)
                            ?
                            <Button
                                className='helikaButtonClass'
                                sx={{
                                    width: '15em',
                                    borderRadius: '0.5em !important',
                                    background: 'red !important',
                                    padding: '0.5em 2em 0.5em 2em'
                                }}
                                onClick={() => {
                                    const unlayer = emailEditorRef.current?.editor;
                                    unlayer?.exportHtml(function (data) {
                                        setCurrentAction((prevState: any) => {
                                            return {
                                                ...prevState,
                                                design: data.design,
                                                html: data.html,
                                                name: actionName,
                                                sender: sender,
                                                subject: subject,
                                            }
                                        })
                                    })
                                    if (editMode) {
                                        unlayer?.showPreview({
                                            device: 'desktop',
                                        })
                                    } else {
                                        unlayer?.hidePreview()
                                    }
                                    setEditMode(prevState => !prevState)
                                }}
                            >
                                {editMode ? 'CONTINUE' : 'EDIT'}
                            </Button>
                            : null
                    }
                </Box>
                <Box
                    id='emailEditorBox'
                    sx={{
                        border: 'solid',
                        borderColor: newColor.darkGunmetal,
                        borderRadius: '1em',
                        flex: 1,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto'
                    }}
                >
                    {
                        isLoading
                            ? <LoadingComponent />
                            : currentAction
                                ?
                                <Box
                                    id='editorBox'
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flex: 1,
                                        maxHeight: '100%',
                                        background: theme.palette.mode === 'dark' ? newColor.midnight : newColor.white
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            width: `calc(100% - ${editMode ? '0em' : '25em'})`,
                                            overflowY: 'auto',
                                            maxHeight: '100%',
                                            padding: '0 1em 0 1em !important',
                                            justifyContent: 'center'
                                        }}
                                        id='boxhere'
                                    >
                                        <EmailEditor
                                            options={{
                                                fonts: {
                                                    showDefaultFonts: true,
                                                    customFonts: customFonts,
                                                },
                                                features: {
                                                    sendTestEmail: true,
                                                    preview: false
                                                },
                                                user: {
                                                    id: 1,
                                                    signature: createSignature(),
                                                    name: userData?.name, // optional
                                                    email: userData?.email
                                                },
                                                displayMode: 'email',
                                                designMode: 'email',
                                                projectId: 240605,
                                                appearance: {
                                                    theme: `classic_${theme.palette.mode}`
                                                },
                                                id: 'editor',
                                            }}
                                            style={{
                                                height: 'calc(100% - 1em)',
                                                borderRadius: '1em',
                                                margin: 'auto'
                                            }}
                                            editorId='editor'
                                            ref={emailEditorRef}
                                            onReady={onReady}
                                            projectId={240605}
                                        />
                                    </Box>
                                    {
                                        !editMode &&
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                                width: '25em',
                                                overflowY: 'auto',
                                                gap: 2,
                                                padding: '1em',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    color: `${theme.palette.mode === 'dark' ? newColor.white : newColor.black} !important`,
                                                    fontWeight: 900,
                                                    padding: '0em 2em 1em 0',
                                                    fontSize: '1.5em',
                                                    width: '100%',
                                                    borderBottom: 'solid',
                                                    borderColor: newColor.darkGunmetal,
                                                    borderRadius: '0em !important',
                                                }}
                                            >
                                                Settings
                                            </Box>
                                            <Box>
                                                Email Details
                                            </Box>
                                            <Box
                                                sx={{
                                                    fontWeight: 900
                                                }}
                                            >
                                                Sender
                                            </Box>
                                            <TextField
                                                className={
                                                    theme.palette.mode === 'dark'
                                                        ? 'textFieldClass'
                                                        : 'textFieldClassLight'
                                                }
                                                sx={{
                                                    background: newColor.black,
                                                    borderRadius: '0.3em !important',
                                                    borderColor: 'black !important',
                                                    border: 'solid'
                                                }}
                                                value={sender}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setSender(event.target.value);
                                                }}
                                                size={'small'}
                                            />
                                            <Box
                                                sx={{
                                                    fontWeight: 900
                                                }}
                                            >
                                                Subject
                                            </Box>
                                            <TextField
                                                className={
                                                    theme.palette.mode === 'dark'
                                                        ? 'textFieldClass'
                                                        : 'textFieldClassLight'
                                                }
                                                sx={{
                                                    background: newColor.black,
                                                    borderRadius: '0.3em !important',
                                                    borderColor: 'black !important',
                                                    border: 'solid'
                                                }}
                                                value={subject}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setSubject(event.target.value);
                                                }}
                                                size={'small'}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: '1em 0 2em 0',
                                                }}
                                            >
                                                <div style={{ background: newColor.darkGunmetal, height: '2px', width: '100%' }}></div>
                                                <Box
                                                    sx={{
                                                        fontWeight: 900,
                                                        padding: '1em 0 1em 0'
                                                    }}
                                                >
                                                    Audience
                                                </Box>
                                                <Select
                                                    required
                                                    size="small"
                                                    style={{ height: '2.5em' }}
                                                    onChange={(e) => {
                                                        setAudience(e.target.value)
                                                    }}
                                                    value={audiences?.find(item => item.query_action_id === audience) ? audiences?.find(item => item.query_action_id === audience)?.query_action_id : ''}
                                                    sx={{
                                                        borderRadius: '0.3em !important',
                                                        background: theme.palette.mode === 'dark' ? newColor.black : newColor.white,
                                                    }}
                                                >
                                                    {audiences?.map((audience: any) => (
                                                        <MenuItem key={audience.query_action_id} value={audience.query_action_id}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    gap: '7px',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Typography sx={{ fontSize: '14px' }}>{audience.query_action_description}</Typography>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <div style={{ background: newColor.darkGunmetal, height: '2px', width: '100%', margin: '2em 0 2em 0' }}></div>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        fontWeight: 900
                                                    }}
                                                >
                                                    Schedule <div style={{ fontWeight: 400, marginLeft: '0.5em' }}>({`Timezone: ${moment.tz.zone(moment.tz.guess())?.abbr((new Date()).getTime())}`})</div>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            paddingRight: '1em',
                                                            margin: '0.8em 0 auto 0',
                                                        }}
                                                    >
                                                        Send Out
                                                    </Box>
                                                    <Select
                                                        displayEmpty
                                                        value={frequency}
                                                        onChange={(e) => {
                                                            setFrequency(e.target.value);
                                                        }}
                                                        sx={{
                                                            width: '10em',
                                                            height: '2em',
                                                            borderRadius: '0.3em',
                                                            textAlign: 'center',
                                                            background: theme.palette.mode === 'dark' ? newColor.black : newColor.white,
                                                            margin: '0.5em 0 auto 0',
                                                        }}
                                                    >
                                                        <MenuItem key={`once`} value={'once'}>
                                                            Once
                                                        </MenuItem>
                                                        <MenuItem key={`daily`} value={'daily'}>
                                                            Daily
                                                        </MenuItem>
                                                        <MenuItem key={`weekly`} value={'weekly'}>
                                                            Weekly
                                                        </MenuItem>
                                                        <MenuItem key={`monthly`} value={'monthly'}>
                                                            Monthly
                                                        </MenuItem>
                                                    </Select>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    {
                                                        frequency === 'once' &&
                                                        <Box
                                                            sx={{
                                                                margin: 'auto 0 auto 0',
                                                                padding: '0.5em 0.5em 0 0'
                                                            }}
                                                        >
                                                            at
                                                        </Box>
                                                    }
                                                    {
                                                        frequency === 'once' &&
                                                        <DateInputField
                                                            value={onceDate}
                                                            setValue={setOnceDate}
                                                            minDate={new Date(Date.now())}
                                                            open={isOnceDateOpen}
                                                            setOpen={setOnceDateOpen}
                                                            showTimezone={false}
                                                        />
                                                    }
                                                    {
                                                        frequency === 'daily' &&
                                                        <Box
                                                            sx={{
                                                                margin: 'auto 0 auto 0',
                                                                padding: '0.5em 0.5em 0 0',
                                                            }}
                                                        >
                                                            every
                                                        </Box>
                                                    }
                                                    {
                                                        frequency === 'daily' &&
                                                        <TextField
                                                            // ref={emailRef}
                                                            value={dailyFrequency}
                                                            onChange={(e) => {
                                                                if (Number(e.currentTarget.value) === 0) {
                                                                    setDailyFrequency(1)
                                                                    return
                                                                }
                                                                setDailyFrequency(Number(e.currentTarget.value))
                                                            }}
                                                            sx={{
                                                                borderRadius: '0.3em',
                                                                height: '1.9em',
                                                                width: '5em',
                                                                background: theme.palette.mode === 'dark' ? newColor.black : newColor.white,
                                                                "& .MuiInputBase-input": { lineHeight: '1em', height: '0.9em', borderRadius: '100px' },
                                                                // "& .MuiInputBase-root": { width: 'auto', maxWidth: '100%', outline: '2em', borderRadius: '2em' },
                                                                "& .MuiButtonBase-root": { padding: '0px' },
                                                                margin: '0.5em 0 auto 0'
                                                            }}
                                                            size={'small'}
                                                            type={'number'}
                                                            placeholder="1"
                                                        />
                                                    }
                                                    {
                                                        frequency === 'daily' &&
                                                        <Box
                                                            sx={{
                                                                margin: 'auto 0 auto 0',
                                                                padding: '0.5em 0em 0 0.5em',
                                                            }}
                                                        >
                                                            days
                                                        </Box>
                                                    }
                                                </Box>
                                                {
                                                    weeklyDays && frequency === 'weekly' &&
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingTop: '0.5em',
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[0]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[0] = !prevState[0]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Mon"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[1]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[1] = !prevState[1]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Tue"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[2]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[2] = !prevState[2]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Wed"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[3]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[3] = !prevState[3]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Thu"
                                                        />
                                                    </Box>
                                                }
                                                {
                                                    weeklyDays && frequency === 'weekly' &&
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingTop: '0.5em',
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[4]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[4] = !prevState[4]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Fri"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[5]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[5] = !prevState[5]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Sat"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={weeklyDays[6]}
                                                                    onChange={(val) => {
                                                                        setWeeklyDays(prevState => {
                                                                            let newState = [...prevState]
                                                                            newState[6] = !prevState[6]
                                                                            return newState
                                                                        })
                                                                    }}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Sun"
                                                        />
                                                    </Box>
                                                }
                                                {
                                                    ['monthly'].includes(frequency) &&
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            marginTop: '0.5em'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding: '0.5em 0.5em 0 0',
                                                                margin: 'auto 0 auto 0',
                                                                textWrap: 'nowrap',
                                                            }}
                                                        >
                                                            Days of the Month
                                                        </Box>
                                                        <TextField
                                                            className={
                                                                theme.palette.mode === 'dark'
                                                                    ? 'textFieldClass'
                                                                    : 'textFieldClassLight'
                                                            }
                                                            sx={{
                                                                background: newColor.black,
                                                                borderRadius: '0 !important'
                                                            }}
                                                            value={monthlyDates}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                setMonthlyDates(event.target.value);
                                                            }}
                                                            placeholder='e.g. 1, 7, 10'
                                                            size={'small'}
                                                        />
                                                    </Box>
                                                }
                                                {
                                                    !['once'].includes(frequency) &&
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            paddingTop: '0.5em',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding: '0.5em 0.5em 0 0',
                                                                margin: 'auto 0 auto 0',
                                                            }}
                                                        >
                                                            starting on the
                                                        </Box>
                                                        <DateInputField
                                                            // title={'Start Date'}
                                                            value={monthlyDate}
                                                            setValue={setMonthlyDate}
                                                            minDate={new Date(Date.now())}
                                                            open={isMonthlyStartDateOpen}
                                                            setOpen={setMonthlyStartDateOpen}
                                                            showTimezone={false}
                                                        />
                                                    </Box>
                                                }
                                                {
                                                    !['once'].includes(frequency) &&
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            marginTop: '1em'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                margin: 'auto 0 auto 0',
                                                                padding: '0.5em 0.5em 0 0'
                                                            }}
                                                        >
                                                            End Date
                                                        </Box>
                                                        <DateInputField
                                                            value={endDate}
                                                            setValue={setEndDate}
                                                            minDate={new Date(Date.now())}
                                                            open={isEndDateOpen}
                                                            setOpen={setEndDateOpen}
                                                            showTimezone={false}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginTop: 'auto'
                                                }}
                                            >
                                                <Button
                                                    className='helikaButtonClass'
                                                    sx={{
                                                        width: '15em',
                                                        borderRadius: '0.5em !important',
                                                        background: 'transparent !important',
                                                        padding: '0.5em 2em 0.5em 2em',
                                                        color: `${theme.palette.mode === 'dark' ? newColor.white : newColor.black} !important`,
                                                    }}
                                                    onClick={() => {
                                                        navigate('/email/campaigns')
                                                    }}
                                                >
                                                    CANCEL
                                                </Button>
                                                <Button
                                                    className='helikaButtonClass'
                                                    sx={{
                                                        width: '15em',
                                                        borderRadius: '0.5em !important',
                                                        background: 'red !important',
                                                        padding: '0.5em 2em 0.5em 2em',
                                                    }}
                                                    onClick={saveHandler}
                                                >
                                                    SAVE
                                                </Button>
                                            </Box>
                                            <Button
                                                className='helikaButtonClass'
                                                sx={{
                                                    width: '15em',
                                                    borderRadius: '0.5em !important',
                                                    background: 'transparent !important',
                                                    padding: '0.5em 2em 0.5em 2em',
                                                    border: 'solid !important',
                                                    borderColor: 'red !important',
                                                    margin: '0 auto 0 auto',
                                                    minHeight: '3em !important',
                                                    color: `${theme.palette.mode === 'dark' ? newColor.white : newColor.black} !important`,
                                                }}
                                                onClick={saveTemplateHandler}
                                            >
                                                SAVE AS TEMPLATE
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                                :
                                <Box
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            fontSize: '2.5em',
                                            margin: '1em auto 0 auto',
                                            textAlign: 'center',
                                            fontWeight: 100,
                                            padding: '0 1em 0 1em',
                                            lineHeight: '1em'
                                        }}
                                    >
                                        No Template found
                                    </Box>
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            fontWeight: 100,
                                            color: newColor.gray,
                                            margin: '0.5em auto 2em auto',
                                            padding: '0 1em 0 1em'
                                        }}
                                    >
                                        Create a new action by starting from scratch, choosing a template base, or copying an existing action.
                                    </Box>
                                    <Button
                                        sx={{
                                            display: 'flex !important',
                                            flexDirection: 'row !important',
                                            width: '20em',
                                            margin: '0.5em auto 0.5em auto',
                                            justifyContent: 'start !important',
                                            fontWeight: 900,
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            padding: '1em 2em 1em 2em',
                                            textTransform: 'none',
                                            fontSize: '1em',
                                            outline: 'solid',
                                            outlineColor: newColor.darkGunmetal,
                                        }}
                                        className='templateEditorButtons'
                                        onClick={() => { setCurrentAction({}) }}
                                    >
                                        <CreateNewEmailIcon style={{ marginRight: '0.5em' }} />
                                        <Box>Create New Email</Box>
                                    </Button>
                                    <Button
                                        sx={{
                                            display: 'flex !important',
                                            flexDirection: 'row !important',
                                            width: '20em',
                                            margin: '0.5em auto 0.5em auto',
                                            justifyContent: 'start !important',
                                            fontWeight: 900,
                                            color: theme.palette.mode === 'dark' ? 'white' : 'black',
                                            padding: '1em 2em 1em 2em',
                                            textTransform: 'none',
                                            fontSize: '1em',
                                            outline: 'solid',
                                            outlineColor: newColor.darkGunmetal
                                        }}
                                        className='templateEditorButtons'
                                        onClick={() => { setIsChooseExistingTemplateModal(true) }}
                                    >
                                        <ExistingTemplateIcon style={{ marginRight: '0.5em' }} />
                                        Choose Existing Template
                                    </Button>
                                    <DuplicateActionButtonList
                                        actions={actions}
                                        setActions={setActions}
                                        setCurrentAction={setCurrentAction}
                                    />
                                </Box>
                    }
                </Box>
            </Box>
            <ChooseExistingTemplateModal
                open={isChooseExistingTemplateModal}
                setOpen={setIsChooseExistingTemplateModal}
                setApplyTemplate={(template: any) => {
                    setCurrentAction(() => {
                        let newTemplate = { ...template }
                        delete newTemplate.id
                        return newTemplate
                    })
                }}
                templates={templates}
                setTemplates={setTemplates}
            />
        </div >
    )


}