export const palette = {
  primary: '#A50085',
  linkText: '#ff2d97',
  primaryLight: '#a13b8d',
  primaryBg: '#634c5e',
  secondary: '#808080',
  font: '#fff',
  bg: '#000',
  bgPage: '#000',
  bgLight: '#181818',
  bg14: '#141414',
  bgPale: '#415B6D',
  error: '#ea335f',
  transparent: '#00000000',
  bgExtraLight: '#090909',
  barChart1: '#FF005C',
  barChart2: '#00C4A3',
  barChart3: '#6926AD',
  barChart4: '#F1CF63',
  heatMap1Cold: '#CBEAC6',
  heatMap1Hot: '#016429',
  heatMap2Cold: '#FCCFCC',
  heatMap2Hot: '#700174',
  primaryText: '#000',
  primaryBorder: '#545454',
}

export const lightPalette = {
  primary: '#A50085',
  linkText: '#ff2d97',
  primaryLight: '#a13b8d',
  primaryBg: '#634c5e',
  secondary: '#808080',
  font: '#fff',
  bg: '#000',
  bgPage: '#e2e2e2',
  bgLight: '#181818',
  bgPale: '#415B6D',
  error: '#ea335f',
  transparent: '#00000000',
  bgExtraLight: '#090909',
  barChart1: '#FF005C',
  barChart2: '#00C4A3',
  barChart3: '#6926AD',
  barChart4: '#F1CF63',
  heatMap1Cold: '#CBEAC6',
  heatMap1Hot: '#016429',
  heatMap2Cold: '#FCCFCC',
  heatMap2Hot: '#700174',
  primaryText: '#fff',
  primaryBorder: '#B9B9B9',
}

export const newColor = {
  charcoal: '#141414',
  midnight: '#181818',
  ebony: '#1B1B1B',
  graphite: '#202020',
  darkSlate: '#222222',
  gunMetal: '#2E2E2E',
  night: '#303030',
  lead: '#323232',
  malachite: '#33CF4D',
  darkGunmetal: '#373737',
  dimGray: '#383838',
  outerSpace: '#404040',
  raisinBlack: '#4146AE',
  darkGray: '#505050',
  jet: '#545454',
  byzantium: '#612EA6',
  shamrock: '#65C466',
  indigo: '#6926AD',
  smokyGray: '#696969',
  dimSlate: '#6B6B6B',
  babyBlueEyes: '#868CFC',
  gray: '#888888',
  oceanBoatBlue: '#8AE0C8',
  caribbeanGreen: '#8BE1C9',
  spanishGray: '#909090',
  electricPurple: '#9654F0',
  cosmicCobalt: '#972081',
  richElectricBlue: '#9747FF',
  darkGrayishGray: '#A9A9A9',
  boulder: '#B3B3B3',
  silver: '#C0C0C0',
  platinum: '#CFBEBE',
  lightGray: '#D5D3D3',
  gainsboro: '#DCDCDC',
  cultured: '#E2E2E2',
  ghostWhite: '#E9E9E9',
  jazzberryJam: '#EA335F',
  cosmicLatte: '#F2F2F2',
  lemonGlacier: '#FFE175',
  white: '#FFFFFF',
  black: '#000000',
  lightOffWhite: '#F3F6F9',
  gunmetalGray: '#1C2025',
  tertiary: '#272727',
  transparent: '#00000000',
  whiteSmoke: '#F8F8F8'
}
