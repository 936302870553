import { ReactComponent as TabManagementIcon } from 'src/assets/tab_management_icon.svg'
import { ReactComponent as UpdateTabOrderIconImg } from 'src/assets/update_tab_order_icon.svg'
import loadingSVG from 'src/assets/loading.svg'

import React, { useEffect, useState } from 'react'
import _ from 'lodash-es'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useAppSelector } from 'src/state'
import { DragDropContext, type DropResult } from '@hello-pangea/dnd'
import { produce } from 'immer'
import { Box, styled as muiStyled } from '@mui/material'

import {
  ChildTab,
  DroppableTypeChild,
  DroppableTypeParent,
  ParentTab,
  TabData,
  TabLevel,
} from './type'
import ModuleTab from './ModuleTab'
import { useActionPopover } from '../ProfileEditToggle'
import { useUpdateTabOrder } from '../useUpdateTabOrder'
import { useActionSave } from './ActionContext'
import { StyledSvg } from './styled'
import { useIsEditing } from '../IsEditingContext'
import { newColor } from '../../../../../consts/colors'

const ProfileCardHeading = muiStyled('h2')(({ theme }) => ({
  fontSize: '1.25rem',
  lineHeight: 1.125,
  textTransform: 'uppercase',
  fontWeight: 400,
  display: 'flex',
  gap: '0.5rem',
  margin: '0 0 1.125rem',
  color: theme.palette.text.primary,
}))

const ProfileCardDesciprtion = muiStyled('h3')(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  fontSize: '0.9375rem',
  lineHeight: 1.27,
  textTransform: 'uppercase',
  marginLeft: '2rem',
  fontWeight: 400,
  color: theme.palette.text.primary,
}))

export default function TabManagement() {
  return (
    <React.Fragment>
      <ProfileCardHeading>
        <StyledSvg>
          <TabManagementIcon />
        </StyledSvg>
        Tab Management
      </ProfileCardHeading>
      <ProfileCardDesciprtion>
        <StyledSvg>
          <UpdateTabOrderIconImg />
        </StyledSvg>
        Update tabs order
      </ProfileCardDesciprtion>
      <TabOrder />
    </React.Fragment>
  )
}

const ActionButton: any = styled.button`
  width: 97px;
  height: 26px;
  background: linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%);
  border-radius: 17px;
  border: none;
  color: ${newColor.white};
  text-transform: uppercase;
  margin-right: 14px;
  opacity: ${(props: any) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
  &:hover {
    cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'pointer')};
    background: linear-gradient(272deg, ${newColor.jazzberryJam} 1.91%, ${newColor.indigo} 98.09%);
  }
`

function TabOrder() {
  const user = useAppSelector((state) => state.user)
  const userData = useSelector((state: any) => state.user)
  const sdk = useSelector((state: any) => state.sdk)
  const { isEdit, setIsEdit } = useActionPopover()
  const { trigger } = useUpdateTabOrder()
  const [tabData, setTabOrder] = useState<TabData>(user.tabs)
  const { isLoading } = useActionSave()
  const { setIsEditing } = useIsEditing()

  useEffect(() => {
    setTabOrder(user.tabs)
  }, [user.tabs])

  const handleReorder = (results: DropResult) => {
    const { index: prioritySrc } = results.source
    const { droppableId: droppableIdDes, index: priorityDes } =
      results.destination || {}

    if (
      droppableIdDes === undefined ||
      prioritySrc === undefined ||
      priorityDes === prioritySrc
    ) {
      return
    }

    const droppableType = results.type as
      | DroppableTypeParent
      | DroppableTypeChild
    const [targetTabLevel, moduleTabId, parentTabId] = droppableType.split('-')

    const produceNewTabData = produce((draft: TabData) => {
      const newTabOrder = draft

      if (targetTabLevel === TabLevel.Parent) {
        const tabList = newTabOrder[moduleTabId]

        const sortedTabs = Object.values(tabList).sort(
          (a, b) => a.priority - b.priority,
        )

        const reOrderedTabs = reorder(
          sortedTabs,
          results.source.index,
          results.destination?.index!,
        )

        for (const index in reOrderedTabs) {
          const tabId = reOrderedTabs[index].id
          tabList[tabId].priority = Number(index) + 1
        }
      } else if (targetTabLevel === TabLevel.Child) {
        const childTabs = newTabOrder[moduleTabId][parentTabId].children
        const sortedTabs = Object.values(childTabs).sort(
          (a, b) => a.priority - b.priority,
        )

        const reOrderedTabs = reorder(
          sortedTabs,
          results.source.index,
          results.destination?.index!,
        )
        for (const index in reOrderedTabs) {
          const tabId = reOrderedTabs[index].id
          const tab = childTabs.filter((tab) => tab.id === tabId)[0]
          tab.priority = Number(index) + 1
        }
      }

      draft = newTabOrder
    })

    setTabOrder(produceNewTabData(tabData))
  }

  const submitNewTabOrder = async () => {
    await trigger({ tabs: tabData })
    if (!_.isEmpty(sdk)) {
      await sdk.createEvent([
        {
          game_id: 'helika_portal',
          event_type: 'organization_configuration_change',
          event: {
            event_sub_type: 'portal_configuration_change',
            configuration_type: 'tab_layout',
            user_identifier: userData?.id,
            user_email: userData?.email,
            user_org_identifier: userData?.organization_id,
            user_org_name: userData?.organization_name,
            details: {
              change: 'tabs_reordered',
            },
          },
        },
      ])
    }

    setIsEdit(false)
  }

  const cancelChanges = () => {
    setTabOrder(user.tabs)
    setIsEdit(false)
    setIsEditing(false)
  }

  return (
    <>
      <DragDropContext onDragEnd={handleReorder}>
        <ModuleTab tabData={tabData} />
      </DragDropContext>
      {isEdit && (
        <Box
          sx={{
            marginTop: '1rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ActionButton disabled={isLoading} onClick={submitNewTabOrder}>
            {isLoading && (
              <img
                width="12px"
                className="animate-spin"
                src={loadingSVG}
                alt=""
              />
            )}{' '}
            Save
          </ActionButton>
          <ActionButton disabled={isLoading} onClick={cancelChanges}>
            Cancel
          </ActionButton>
        </Box>
      )}
    </>
  )
}

function reorder(
  list: Array<ParentTab[string] | ChildTab>,
  startIndex: number,
  endIndex: number,
) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
