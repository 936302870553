//imports
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as ReactTooltip from 'react-tooltip';
import React from "react";
import { useTheme } from '@mui/material';
import { StyledSvg } from 'src/components/organisms/v2/tabs/styled';
import { ReactComponent as VerticalEllipsis } from 'src/assets/vertical_ellipsis_icon.svg'
import { newColor } from 'src/consts/colors';
import { useNavigate } from 'react-router-dom';

//contexts

//services

//components

//assets
//---------------------------------------------------------------------------------

function ActionsOptionsMenu({
    selectedCampaign,
    action,
    showConfirmDeleteModal
}: {
    selectedCampaign: any,
    action: any,
    showConfirmDeleteModal: any
}) {

    const navigate = useNavigate()
    const theme: any = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="flex flex-col">
            <ReactTooltip.Tooltip />
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    marginTop: '-0.7em'
                }}
            >
                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                    }}
                >
                    <VerticalEllipsis
                        style={{
                            cursor: 'pointer'
                        }}
                    />
                </StyledSvg>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                disableScrollLock={true}
                sx={{
                    "& .MuiList-root":
                        { backgroundColor: theme.palette.background.default, padding: 0 },
                    "& .MuiMenu-paper":
                        { backgroundColor: theme.palette.background.default, border: theme.palette.mode === 'dark' ? 'inset' : '', borderColor: newColor.gray, marginTop: '1em' },
                    "& .MuiButtonBase-root.MuiMenuItem-root:nth-last-of-type(n + 2)": {
                        borderBottom: 'inset', borderBottomWidth: '0.5px', borderColor: '#676767',
                    }
                }}
            >
                <MenuItem
                    onClick={() => {
                        navigate(`/email/actions?campaign-id=${selectedCampaign?.id}&action-id=${action.id}`)
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={async () => showConfirmDeleteModal(true)}
                >
                    Delete
                </MenuItem>
            </Menu>
        </div>
    )
}

export default ActionsOptionsMenu