import React, { useRef, useState } from 'react';
import LoadingComponent from 'src/components/LoadingComponent';
import { Box, useTheme } from '@mui/system';
import { Button } from '@mui/material';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import { useSelector } from 'react-redux';
import { customFonts } from '../../../utils/string';
import { sampleDesign } from 'src/utils/email';

var CryptoJS = require('crypto-js');

export default function EmailTemplateBuilder() {

    const theme = useTheme()
    const emailEditorRef = useRef<EditorRef>(null);
    const [isLoading,] = useState<boolean>(false)
    const userInfo = useSelector((state: any) => state.user)

    function createSignature() {
        const crypted = CryptoJS.HmacSHA256("1", process.env.REACT_APP_UNLAYER_SECRET!)
        return crypted.toString(CryptoJS.enc.Hex)
    }

    const newTemplate = () => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.loadBlank()
    };

    const onReady: EmailEditorProps['onReady'] = (unlayer) => {
        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadTemplate(callback) or unlayer.exportHtml(callback)

        const templateJson: any = sampleDesign;
        // if (templateId) {
        unlayer.loadDesign(templateJson);
        // } else {
        //     unlayer?.loadBlank()
        // }

    };

    const mode: EmailEditorProps['displayMode'] = 'email'

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {
                isLoading
                    ?
                    <LoadingComponent />
                    :
                    <Box
                        style={{
                            width: '100%',
                            height: '2em',
                        }}
                        display={'flex'}
                        flexDirection={'row'}
                        gap={2}
                    >
                        <Button className='helikaButtonClass' sx={{ borderRadius: '0.5em !important' }} onClick={newTemplate}>New Blank Template</Button>
                    </Box>
            }
            <EmailEditor
                options={{
                    fonts: {
                        showDefaultFonts: true,
                        customFonts: customFonts,
                    },
                    features: {
                        sendTestEmail: true,
                    },
                    user: {
                        id: 1,
                        signature: createSignature(),
                        name: userInfo?.name, // optional
                        email: userInfo?.email
                    },
                    displayMode: mode,
                    // customCSS: [`${process.env.REACT_APP_AUTH_REDIRECT_URI}/unlayer.css`],
                    // customJS: [`${process.env.REACT_APP_AUTH_REDIRECT_URI}/unlayer.jsx`],
                    designMode: 'email',
                    projectId: 240605,
                    appearance: {
                        theme: `classic_${theme.palette.mode}`
                    },
                    id: 'editor'
                }}
                style={{
                    height: 'calc(100% - 0.4em)',
                    width: '100%'
                }}
                editorId='editor'
                ref={emailEditorRef}
                onReady={onReady}
            />
        </div>
    )


}