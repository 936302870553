import { useSelector } from 'react-redux'
import { useContext, useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import SetUserOrgRole from '../../../components/admin_new/setUserOrgRole'
import CircularPageHeader from '../../../components/atoms/v2/circularPageHeader'
import PageContainer from '../../../components/atoms/v2/pageContainer'
import { Auth0Context } from '../../../contexts/Auth0Context'
import {
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
  isOrgAdmin,
} from '../../../utils/user'
import {
  getCurrentUserOrg as getCurrentUserOrgAPI,
  getInvitedUserApi,
} from '../../../utils/api/queries'
import { ReactComponent as APIsIcon } from 'src/assets/API_Icon.svg'
import { ReactComponent as OrgMembersIcon } from 'src/assets/organization_member_icon.svg'
import { ReactComponent as TabManagementIcon } from 'src/assets/tab_management_icon.svg'
import { ReactComponent as AccessIcon } from 'src/assets/access_icon.svg'
import { ReactComponent as EmailIcon } from 'src/assets/Email_Icon.svg'
import { ReactComponent as TabOrderIcon } from 'src/assets/large_text_icon.svg'
import OrgImg from '../../../assets/Org_Management_Icon.svg'
import Card from '../../../components/atoms/v2/card'
import Members from '../../../components/OrganizationManagement/Members'

import { useUpdateOrgTabOrder } from '../../../components/organisms/v2/tabs/useUpdateTabOrder'
import TabManagement from '../../../components/OrganizationManagement/TabManagement'
import ErrorComponent from '../../../pages/CommonPages/ErrorComponent'
import { toast } from 'react-toastify'
import InviteUsers from 'src/components/OrganizationManagement/InviteUsers'
import InviteUsersModal from 'src/components/popups/InviteUsersModal'

interface Org {
  label: string
  value: number
}

export default function OrganizationManagement() {
  const [hasError, setHasError] = useState<boolean>(false)

  const sdk = useSelector((state: any) => state.sdk)
  const userData = useSelector((state: any) => state.user)
  const { trigger } = useUpdateOrgTabOrder()

  const { getWithAccessToken, isLoading, isAuthenticated } =
    useContext(Auth0Context)
  const [orgs, setOrgs] = useState<Org[]>([])
  const [orgData, setOrgData] = useState<any>(null)

  const [invitedUsersData, setInvitedUsersData] = useState<any>(null)

  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)

  const [searchTerm, setSearchTerm] = useState<any>('')

  const toggleInviteModal = (shouldFetchInvitedUsers = false) => {
    setShowInviteModal(!showInviteModal)
    if (shouldFetchInvitedUsers) {
      getInvitedUsers()
    }
  }

  const logInvitedUserEvent = async (invitedUserEmail: string) => {
    if (_.isEmpty(sdk)) return
    await sdk.createEvent([
      {
        game_id: 'helika_portal',
        event_type: 'interaction_event',
        event: {
          event_sub_type: 'client_user_invitation',
          user_identifier: userData?.id,
          user_email: userData?.email,
          user_org_identifier: userData?.organization_id,
          user_org_name: userData?.organization_name,
          client_admin_event: 'portal_invitation',
          invited_users: invitedUserEmail,
        },
      },
    ])
  }

  const getOrg = useCallback(async () => {
    try {
      const orgData = await getCurrentUserOrgAPI(
        getWithAccessToken,
        userData.organization_id,
      )
      if (!orgData || !orgData.results) return
      setOrgData(orgData.results)
    } catch (e: any) {
      toast.error(e?.message)
      setHasError(true)
    }
  }, [getWithAccessToken, userData.organization_id])

  const onSave = useCallback(
    async ({ tabs }: any) => {
      await trigger({ tabs, organizationId: userData.organization_id })
      getOrg()
      if (!_.isEmpty(sdk)) {
        await sdk.createEvent([
          {
            game_id: 'helika_portal',
            event_type: 'organization_configuration_change',
            event: {
              event_sub_type: 'portal_configuration_change',
              configuration_type: 'tab_layout',
              user_identifier: userData?.id,
              user_email: userData?.email,
              user_org_identifier: userData?.organization_id,
              user_org_name: userData?.organization_name,
            },
          },
        ])
      }
    },
    [
      getOrg,
      trigger,
      userData.organization_id,
      sdk,
      userData.organization_name,
      userData.email,
      userData.id,
    ],
  )

  const getInvitedUsers = useCallback(async () => {
    await getInvitedUserApi(getWithAccessToken, userData.organization_id)
      .then((resp: any) => {
        setInvitedUsersData(resp.results)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [getWithAccessToken, userData.organization_id])

  useEffect(() => {
    getInvitedUsers()
  }, [getWithAccessToken, getInvitedUsers])

  useEffect(() => {
    async function getInitialData() {
      if (_.isEmpty(userData) || !userData?.organization_name || !userData?.organization_id) return
      try {
        setOrgs(
          [{
            label: userData.organization_name,
            value: userData.organization_id,
          }]
        )
        await getOrg()
      } catch (e: any) {
        toast.error(e?.message)
        setHasError(true)
        console.log(e)
      }
    }

    if (
      isLoading ||
      !isAuthenticated ||
      _.isEmpty(userData) ||
      !userData?.module_access_level ||
      !('4' in userData?.module_access_level)
    )
      return

    if (
      (!isSuperAdmin(userData) &&
        !isHelikaAdmin(userData) &&
        !isHelikaSuperAdmin(userData) &&
        !isOrgAdmin) ||
      userData?.module_access_level['4'] !== 'editor'
    ) {
      window.location.replace('/profile')
    }

    getInitialData()
  }, [getWithAccessToken, isAuthenticated, isLoading, getOrg, userData])

  return hasError ? (
    <PageContainer>
      <ErrorComponent />
    </PageContainer>
  ) : (
    <PageContainer>
      <CircularPageHeader text="ORGANIZATION MANAGEMENT" icon={OrgImg} />
      <Card label="APIs" icon={<APIsIcon />}>
        <div style={{ marginTop: '20px' }}></div>
        <SetUserOrgRole orgs={orgs} />
      </Card>
      <Card
        label="ORGANIZATION MEMBERS"
        icon={<OrgMembersIcon />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <Card
          access
          subheaderText="ACCESS"
          subheaderIcon={<AccessIcon />}
          onSearch={(e: any) => setSearchTerm(e.target.value)}
        >
          <Members
            orgModuleAccessLevel={orgData?.modules_access_level}
            searchTerm={searchTerm}
          />
        </Card>
        <Card
          invite
          subheaderText="INVITED USERS"
          subheaderIcon={<EmailIcon />}
          onInviteClick={toggleInviteModal}
        >
          <InviteUsers
            invitedUsers={invitedUsersData}
            onUserRemoved={getInvitedUsers}
          />
        </Card>
      </Card>
      <Card
        label="ORGANIZATION TABS"
        icon={<TabManagementIcon />}
        subheaderText="UPDATE TABS ORDER"
        subheaderIcon={<TabOrderIcon />}
      >
        <TabManagement
          tabsData={orgData?.default_tabs || {}}
          onSave={onSave}
          allowReorder
        />
      </Card>
      <InviteUsersModal
        open={showInviteModal}
        setOpen={setShowInviteModal}
        onClose={() => {}}
        onInviteSuccess={(params) => {
          toggleInviteModal(true)
          logInvitedUserEvent(params.emails.join(', '))
        }}
      />
    </PageContainer>
  )
}
