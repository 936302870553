import _ from "lodash";

export function isAdmin(userData: any) {
  return userData?.role === 'admin';
}

export function isSuperAdmin(userData: any) {
  return userData?.role === 'super_admin';
}

export function isHelikaAdmin(userData: any) {
  return userData?.role === 'helika_admin';
}

export function isHelikaSuperAdmin(userData: any) {
  return userData?.role === 'helika_super_admin';
}

export function isOrgAdmin(userData: any) {
  return userData?.org_role === 'admin';
}

export function canAccessTabManagement(userData: any) {
  return isHelikaSuperAdmin(userData) || isSuperAdmin(userData);
}

export function canAccessOnboardingOrAdminPanels(userData: any) {
  return isHelikaSuperAdmin(userData) || isHelikaAdmin(userData);
}

export function getTeamName(userData: any) {
  let team_name = userData?.sigma_team ? userData?.sigma_team : userData?.organization_name;
  return _.startCase(_.toLower(team_name));
}

export function canSeeGDCDemo(USER_DATA: any) {
  return ['helika', 'helika_game_test'].includes(USER_DATA?.organization_name?.toLowerCase());
}

export function canSeeExplore(USER_DATA: any) {
  //orgs
  //5 helika
  //7 proof of play
  //30 treasure
  //94 wanderers 114
  //82 valeria 
  if (process.env.REACT_APP_ENVIRONMENT !== 'PROD') {
    return (process.env.REACT_APP_EXPLORE_FLAG && [5, 7, 30, 82, 114].includes(USER_DATA?.organization_id));
  }
  return (process.env.REACT_APP_EXPLORE_FLAG && [5, 7, 30, 82, 94, 117].includes(USER_DATA?.organization_id));
}

export function canSeeEmailMarketing(USER_DATA: any) {
  if (process.env.REACT_APP_ENVIRONMENT !== 'PROD') {
    return ([5, 47].includes(USER_DATA?.organization_id));
  }
  return ([5].includes(USER_DATA?.organization_id));
}

export function canSeeHelikaAi(USER_DATA: any) {
  return (
    ['helika', 'helika_game_test'].includes(USER_DATA?.organization_name?.toLowerCase()) &&
    USER_DATA?.role?.includes('helika_super_admin')
  )
}